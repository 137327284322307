<template>
    <div>
        <Form v-model="templateFormData" ref="templateForm" />

        <h2>{{text.select_sections}}</h2>

        <div class="table-scroller table-shadow">
            <table class="body">
                <thead>
                <tr>
                    <th class="w-4/12">{{text.property_title}}</th><!-- WAS 2 -->
                    <th class="w-3/12 hidden">{{text.rename}}</th>
                    <th class="w-6/12">{{text.tooltip}}</th><!-- WAS 5 -->
                    <th class="w-2/12">{{text.enable}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in propertyFormData" :key="index">
                    <td v-text="getRowLabel(row[0].value, row[0].id)"></td>
                    <td class="hidden">
                        <Formelement :elementData="row[0]" v-model="row[0].value"
                                     :ref="'element-' + row[1].alias + '-label'"/>
                    </td>
                    <td>
                        <Formelement :elementData="row[1]" v-model="row[1].value"
                                     :ref="'element-' + row[1].alias + '-tooltip'" />
                    </td>
                    <td>
                        <Formelement :elementData="row[2]"
                                     v-model="row[2].value"
                                     :ref="'element-' + row[1].alias + '-disabled'"
                                     v-if="!row[2].canBeDisabled"
                                     :invertDisplay="true"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <router-link :to="{ name: 'AdminTemplateList' }" :title="text.cancel" class="btn btn-tertiary" v-if="this.id == 0">{{text.cancel}}</router-link>
            <a href="#" :title="text.create" class="btn btn-primary btn-smyspace" @click="save($event, true)" @keyup.enter="save($event, false)" v-if="this.id == 0">{{text.create}}</a>
            <a href="#" :title="text.save" class="btn btn-secondary btn-smyspace" @click="save($event, false)" @keyup.enter="save($event, false)" v-if="this.id != 0">{{text.save}}</a>
            <a href="#" :title="text.save_and_continue" class="btn btn-primary" @click="save($event, true)" @keyup.enter="save($event, true)" v-if="this.id != 0">{{text.save_and_continue}}</a>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {log} from "@/shared/utils";
    import lang from '@/shared/lang';
    import {MESSAGE_TYPE_SUCCESS} from '@/shared/consts';

    import {templateActionsMixin} from "@/views/mixins/templateActions";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import Formelement from "@/components/form/formelement";
    import Form from "@/components/form/form";

    export default {
        name: 'TemplateEdit',
        components: {Form, Formelement},
        mixins: [templateActionsMixin, formWrapperMixin],
        data() {
            return {
                templateFormData: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'name',
                        label: 'Name',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'string',
                            required: true,
                            max: 255
                        },
                    },
                    {
                        id: 2,
                        type: 'select',
                        name: `businessUnitIDs`,
                        label: 'Publishing departments',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        validate: {
                            type: 'select',
                            required: true
                        },
                        options: [],
                        autoSelectAndHideOnSingleOption: true
                    }
                ],
                propertiesList: [],
                propertyFormData: []
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            },
            message() {
                return this.$route.params.message
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async initTemplateEdit(){
                log(["Init template edit", this.id]);
                if(this.id == 0){
                    log(["Mounted with NEW template"]);
                    await this.loadTemplateDescriptionByID(1);

                    // Set Page Title
                    this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.text.add) });
                }
                else
                {
                    log(["Mounted with existing template", this.id]);
                    await this.loadTemplateByID(this.id);

                    // Set Page Title
                    this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.text.edit) });
                }
                await this.addTemplateMetaFormData();
                this.initPropertyTable();
            },
            async addTemplateMetaFormData(){
                log(["Adding template meta form data", this.templateData]);

                this.setFormDataValueByName(this.templateFormData, "name", this.getTemplateName());
                this.setFormDataValueByName(this.templateFormData, "businessUnitIDs", this.getTemplateBusinessUnitIDs());
                this.setFormDataOptionsByName(this.templateFormData, "businessUnitIDs", this.parseBusinessUnitsToSelectOptions(await this.loadBusinessUnits()));

            },
            initPropertyTable(){
                log(["Intialising property table", this.getTemplateProperties(), Object.entries(this.getTemplateProperties())]);
                this.propertyFormData = [];

                this.getTemplateProperties().forEach((value, index) => {
                    //log(["Property", index, value[1], this.createFormRow(index, value[1])]);
                    this.propertyFormData.push(this.createFormRow(index, value[1]));
                });
            },
            createFormRow(rowID, rowData) {
                return [
                    {
                        id: rowData.name,
                        type: 'text',
                        name: `label-${rowID}`,
                        label: 'Label',
                        placeholder: "",
                        tooltip: "",
                        value: rowData.label,
                        validate: {
                            type: 'string',
                            required: false,
                            max: 63
                        },
                        classString: "hide-label"
                    },
                    {
                        id: rowData.alias,
                        type: 'text',
                        name: `tooltip-${rowID}`,
                        label: 'Tooltip',
                        placeholder: "",
                        tooltip: "",
                        value: rowData.tooltip,
                        validate: {
                            type: 'string',
                            required: false,
                            max: 511
                        },
                        classString: "hide-label prevent-edit"
                    },
                    {
                        id: rowID + "-excluded",
                        type: 'checkbox',
                        name: `excluded-${rowID}`,
                        label: 'Disabled',
                        text: '',
                        placeholder: "",
                        tooltip: "",
                        value : rowData.excluded,
                        options : [
                            {"value" : "true", "text" : "Disable section"},
                        ],
                        classString: "hide-label",
                        canBeDisabled: rowData.required,
                    },
                ];
            },
            validateAll() {
                let pass = true;

                log(["Saving form", this.$refs]);

                this.propertyFormData.forEach(element => {
                    log(["Ref", element, 'element-' + element[1].alias + '-label', this.$refs['element-' + element[1].alias + '-label'], this.$refs['element-' + element[1].alias + '-label'][0]]);
                    if (!this.$refs['element-' + element[1].alias + '-label'][0].validateElement()) {
                        pass = false;
                    }
                    if (!this.$refs['element-' + element[1].alias + '-tooltip'][0].validateElement()) {
                        pass = false;
                    }
                    if (!this.$refs['element-' + element[1].alias + '-disabled'][0].validateElement()) {
                        pass = false;
                    }
                    log("Current pass state: " + pass);
                });


                return pass;
            },
            async save(e, navigateNextOnSave = false){
                e.preventDefault();
                log(["Saving new template set up", navigateNextOnSave, this.templateFormData, this.propertyFormData]);

                // validate the template form & validate the rest of it
                let resultA = this.$refs.templateForm.validateForm();
                let resultB = this.validateAll();
                if(resultA && resultB){
                    log(["Validation OK"]);

                    // set general template values
                    this.setTemplateName(this.getFormDataValueByName(this.templateFormData, "name" ));
                    this.setTemplateBusinessUnitIDs(this.getFormDataValueByName(this.templateFormData, "businessUnitIDs" ));

                    // loop through properties and try to save them
                    this.propertyFormData.forEach(element => {
                        log(["looping through save", element]);

                        this.setTemplatePropertyLabelByAlias(element[1].id, element[0].value);
                        this.setTemplatePropertyTooltipByAlias(element[1].id, element[1].value);
                        this.setTemplatePropertyExcludedByAlias(element[1].id, element[2].value ? true : false);
                    });

                    // save down to the database
                    let databaseSaveOK = await this.saveTemplate();

                    let thisTemplateID = this.getTemplateID();

                    if(typeof databaseSaveOK !== "boolean"){
                        thisTemplateID = databaseSaveOK;

                        log(["Save value is not a boolean, so should be an ID (meaning a new template)", databaseSaveOK, thisTemplateID]);

                        if(!navigateNextOnSave){
                            this.$router.replace( { name: this.addSectionPrefix('AdminTemplateEdit'), params : { id : thisTemplateID }});
                        }
                    }

                    if(databaseSaveOK && navigateNextOnSave){
                        this.$router.push( { name: 'AdminTemplateEditContent', params : { id : thisTemplateID } } );
                    }
                }
                else
                {
                  log(["FORM NOT OK"]);
                  this.scrollToFirstError();
                }
            },
            showMessage() {
                if(this.message === "iscopy"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : this.text.message_template_copied
                    });
                }
            },
            initEditToggle() {
                let editToggleInputs = document.querySelectorAll(".prevent-edit");
                for(let i = 0; i < editToggleInputs.length; i++){
                    let toggleButton = document.createElement("span");
                    toggleButton.classList.add('allow-edit-trigger');
                    editToggleInputs[i].appendChild(toggleButton);

                    toggleButton.addEventListener("click", function(e){
                        //log("HELP");
                        e.preventDefault();
                        e.target.closest(".prevent-edit").classList.remove("prevent-edit");
                        toggleButton.parentNode.removeChild(toggleButton);
                    })
                }
            },
            getRowLabel(label, id) {
                return label ? label : id;
            }
        },
        async mounted() {
            await this.initTemplateEdit();
            this.showMessage();
            this.initEditToggle();
        },
        watch:{
            /*async $route (){
                await this.initTemplateEdit();
            }*/
            id: {
                handler: function() {
                    this.initTemplateEdit();
                },
                deep: true,
                immediate: true
            }
        },
    }
</script>